import React from "react";
import osagal from "../assets/osagal.jpg";

const Osagallery = () => {
  return (
    <div className="schoolimage">
      <img src={osagal} alt="Old Student Association" />
      <h1 className="Leaderboard">Thank you, Old Student Association</h1>
      <p>
        Your support and cooperation has been immense for this edition of
        Vinterbash. We were much elated with your presence. So we have tried our
        best to give you the moments you spent with us. This is a new effort
        from us, doing it for the first time in Trichy. We've given the
        download link below. As the pictures are of highest quality, the file
        size will be large.
      </p>
      <div className="osabuttons">  
      <a
        href="https://drive.google.com/drive/u/3/folders/18p_5QpyO4GsG2lKWp5pk9PQI3cu6jmy4"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Music</a>
      <a
        href="https://drive.google.com/drive/u/3/folders/1wV8O0nmz-aKUCcB3FcmfqeYnLauOjQ3v"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Dance</a>
       <a
        href="https://drive.google.com/drive/u/3/folders/1Hj7sF0KC_tJ646KQVqKMCZeFOWrRA47m"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Inaugration</a>
       <a
        href="https://drive.google.com/drive/u/3/folders/1FyGGUV7Ub6gyKacDRGOUmkbd_dqie9kx"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Valedictory</a>
       <a
        href="https://drive.google.com/drive/u/3/folders/13CWHU4QUlqS7siqEdnN-3tOMaV_VnVCT"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Sports</a>
        <a
        href="https://drive.google.com/drive/u/3/folders/1XM0T6IChtRCQmKnr_DCQCvmDsCzT1zD2"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Dramatics</a>
       
       <a
        href="https://drive.google.com/drive/u/3/folders/1R1hmnSlpNKKn4OI4WG7wKg4JxrxSzpui"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Quiz</a>
      <a
        href="https://drive.google.com/drive/u/3/folders/1AmFp7FMTwv4tVq2bFJeaGV0rZEaWUl2J"
        className="btn btn-primary"x
        target="_blank"
        rel="noopener noreferrer"
      >English lits</a>
      <a
        href="https://drive.google.com/drive/u/3/folders/1_bdu9c6FA51labdMRWQWkYlAZzezQ7Uk"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >தமிழ்</a>
      <a
        href="https://drive.google.com/drive/u/3/folders/1yjpfRTBnJXQZB4VSD_bX8p-KTbckCIyx"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Art </a>
      <a
        href="https://drive.google.com/drive/u/3/folders/1xIJe5zIZ3ZLmqQ4q9W2SDivsZA3E6dis"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Tech</a>
      <a
        href="https://drive.google.com/drive/u/3/folders/1P1JHQHKNCTDT4LJYwpXR9ZAu8uhG0aRd"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Post Valedictory</a>
        <a
        href="https://drive.google.com/drive/u/3/folders/1Ik9ll-WPHGi2XNqCQod93gmHjEvYZD7C"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Emcees</a>
        <a
        href="https://drive.google.com/drive/u/3/folders/1wSA2mGOAOd_wJwWINZ0Dwfe0klzA7YzM"
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
      >Photography</a>
      </div>
    </div>
  );
};

export default Osagallery;
