// scoredata.js

const cricketMatches = [
  {
    status: "Group stage 6 over match - 1 (Completed)",
    color: "#E76F51",
    school1Name: "Brindhavan",
    school1Score: "47/1",
    school2Name: "St.Joan (Won)",
    school2Score: "48/2",
  },
  {
    status: "Group stage 6 over match - 2 (Completed)",
    color: "#E76F51",
    school1Name: "MGCV (Won)",
    school1Score: "45-2",
    school2Name: "Alpha international",
    school2Score: "24-5",
  },
  {
    status: "Group stage 6 over match - 3 (Completed)",
    color: "#E76F51",
    school1Name: "Chinmaya",
    school1Score: "39-1",
    school2Name: "SAV (Won)",
    school2Score: "40-3",
  },
  {
    status: "Group stage 6 over match - 4 (Completed)",
    color: "#E76F51",
    school1Name: "Vignesh vidhyalaya (Won)",
    school1Score: "73-0",
    school2Name: "Alpha wisdom",
    school2Score: "17-6",
  },
  {
    status: "Group stage 6 over match - 5 (Completed)",
    color: "#E76F51",
    school1Name: "RSK",
    school1Score: "30-3",
    school2Name: "Santhanam (Won)",
    school2Score: "33-1",
  },
  {
    status: "Group stage 6 over match - 6 (Completed)",
    color: "#E76F51",
    school1Name: "Vignesh Public",
    school1Score: "8-6",
    school2Name: "KNMS",
    school2Score: "9-0 (Won)",
  },
  {
    status: "Group stage 6 over match - 7 (Completed)",
    color: "#E76F51",
    school1Name: "St.Joan",
    school1Score: "15-6",
    school2Name: "Vageesha",
    school2Score: "16-0 (Won)",
  },
  {
    status: "Group stage 6 over match - 8 (Completed)",
    color: "#E76F51",
    school1Name: "Akilandeswari",
    school1Score: "44-5 (Won)",
    school2Name: "Aurobindo",
    school2Score: "31-2",
  },
  {
    status: "Group stage 6 over match - 9 (Completed)",
    color: "#E76F51",
    school1Name: "Vignesh Vidyalaya",
    school1Score: "28-0 (Won)",
    school2Name: "MGCV",
    school2Score: "27-2",
  },
  {
    status: "Group stage 6 over match - 10 (Completed)",
    color: "#E76F51",
    school1Name: "Santhanam",
    school1Score: "36-6 (Won)",
    school2Name: "SAV",
    school2Score: "18-6",
  },
  {
    status: "Semifinal - 1 (Completed)",
    color: "#E76F51",
    school1Name: "Vignesh Vidayalaya (Won)",
    school1Score: "51-4 ",
    school2Name: "KNMS",
    school2Score: "41-4",
  },
  {
    status: "Semifinal - 2 (Completed)",
    color: "#E76F51",
    school1Name: "Vageesha  (Won)",
    school1Score: "37-1",
    school2Name: "Santhanam",
    school2Score: "36-1",
  },
  {
    status: "Finals (Live)",
    color: "#E76F51",
    school1Name: "Vageesha",
    school1Score: "28-6",
    school2Name: "Vignesh (Champions)",
    school2Score: "29-5",
  },
];

const footballMatches = [
  {
    status: "Group stage game - 1 (Completed)",
    color: "#E76F51",
    school1Name: "Vageesha (Won)",
    schoolsScore: "5-0 (FT)",
    school2Name: "SAV",
  },
  {
    status: "Group stage game - 2 (Completed)",
    color: "#E76F51",
    school1Name: "RSK (Won)",
    schoolsScore: "2-0 (FT)",
    school2Name: "Srirangam boys",
  },
  {
    status: "Group stage game - 3 (Completed)",
    color: "#E76F51",
    school1Name: "Santhanam (Won)",
    schoolsScore: "3-0 (FT)",
    school2Name: "Chinmaya",
  },
  {
    status: "Group stage game - 4 (Completed)",
    color: "#E76F51",
    school1Name: "Alpha Wisdom (Won) ",
    schoolsScore: "6-0 (FT)",
    school2Name: "Brindhavan",
  },
  {
    status: "Group stage game - 5 (Completed)",
    color: "#E76F51",
    school1Name: "Alpha Cambridge (Won) ",
    schoolsScore: "1-0 (FT)",
    school2Name: "Aurobindo",
  },
  {
    status: "Group stage game - 6 (Completed)",
    color: "#E76F51",
    school1Name: "KNMS (Won) ",
    schoolsScore: "3-2 (FT)",
    school2Name: "St. Joan of Arc",
  },
  {
    status: "Group stage game - 7 (Completed)",
    color: "#E76F51",
    school1Name: "Vageesha (Won) ",
    schoolsScore: "2-1 (FT)",
    school2Name: "Vignesh",
  },
  {
    status: "Group stage game - 8 (Completed)",
    color: "#E76F51",
    school1Name: "Santhanam (Won) ",
    schoolsScore: "2-1 (FT)",
    school2Name: "Alpha Cambridge",
  },
  {
    status: "Group stage game - 9 (Completed)",
    color: "#E76F51",
    school1Name: "TBHSS (Won) ",
    schoolsScore: "4-0 (FT)",
    school2Name: "KNMS",
  },
  {
    status: "Semifinal - I (Completed)",
    color: "#E76F51",
    school1Name: "Santhanam (Won) ",
    schoolsScore: "0-0 (FT), 6-5 Pen",
    school2Name: "Vageesha",
  },
  {
    status: "Semifinal - II (Completed)",
    color: "#E76F51",
    school1Name: "Alpha Wisodom (Won) ",
    schoolsScore: "3-0(Ft)",
    school2Name: "Boys high school ",
  },
  {
    status: "3rd place match(Completed)",
    color: "#E76F51",
    school1Name: "Vageesha ",
    schoolsScore: "0-1 (FT)",
    school2Name: "Boys high school (Won)",
  },
  {
    status: "Finals (Completed)",
    color: "#E76F51",
    school1Name: "Alpha Wisodom (Champions)",
    schoolsScore: "",
    school2Name: "Santhanam",
  },
];

export { cricketMatches, footballMatches };
